import React from 'react';
// import logo from './logo.svg';
import {Router,Route,Switch} from "react-router-dom";
import LandingPage from './views/Landing/LandingPage';
import CPExam from './views/CPExam/CPExam';
import SAExam from './views/SAExam/SAExam';
import WithPeople from './views/WithPeople/WithPeople';
import Expenses from './views/Expenses/Expenses';
import Tokyo from './views/WithPeople/Tokyo';
import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga4';
import './App.css';

export const history = createBrowserHistory()


function App() {
  const TRACKING_ID = "G-BVLZ5W194D"
  ReactGA.initialize(TRACKING_ID)
  
  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <Route path='/' exact>
            <LandingPage/>
          </Route>
          <Route path='/CPExam' exact>
            <CPExam/>
          </Route>
          <Route path='/SAExam' exact>
            <SAExam/>
          </Route>
          {/* <Route path='/withpeople' exact>
            <WithPeople/>
          </Route>
          <Route path='/tokyo' exact>
            <Tokyo/>
          </Route> */}
          {/* <Route path='/expenses' exact>
            <Expenses/>
          </Route> */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
